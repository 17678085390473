import React, { Component } from 'react';
import {
    Link,
} from 'react-router-dom'
class Footer extends Component {
    render() {
        return <footer>
              <div className="content">
                <div className="fourth">
                    <h4>Pages:</h4>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/projects">Projects</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                    </ul>
                  <div className="space small"></div>
                </div>
                <div className="fourth">
                    <h4>Social Links:</h4>
                    <div className="socialLinks">
                        <a href="https://twitter.com/Oliviervademo" target="_black">
                            <img src="/twitter_white.svg" alt="twitter link"/>
                        </a>
                        <a href="https://github.com/vademo" target="_black">
                            <img src="/github_white.svg" alt="github link"/>
                        </a>
                        <a href="https://www.behance.net/vademo" target="_black">
                            <img src="/behance_white.svg" alt="behance link"/>
                        </a>
                    </div>
                  <div className="space small"></div>
                </div>
                <div className="fourth">
                </div>
                <div className="fourth">
                    <h4>Company info:</h4>
                    <p><strong>Name:</strong> Vademo BVBA</p>
                    <p><strong>BTW:</strong> BE 0658 876 062</p>
                    <p><strong>Owner:</strong> Olivier Van den Mooter</p>
                   <p><strong>Email:</strong> <a href="mailto:info@vademo.be" className="email">info@vademo.be</a></p>
                   <div className="space small"></div>
                </div>
              </div>
            </footer>;
    }

}
export default Footer
