import React, { useState } from 'react';
import EditorControls from './editorControls';
import Editor from 'draft-js-plugins-editor';
import {
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
} from 'draft-js';
import createLinkifyPlugin from 'draft-js-linkify-plugin';

const linkifyPlugin = createLinkifyPlugin();

const MyEditor = (props) => {
  const convertedState = convertFromRaw(props.content)
  const [editorState, setEditorState] = useState(EditorState.createWithContent(convertedState));

  const plugins = [
    linkifyPlugin,
  ];

  const setContent = (editorState) => {
    props.change(convertToRaw(editorState.getCurrentContent()));
    setEditorState(editorState);
  }
  const onChange = (editorState) => {
    props.change(convertToRaw(editorState.getCurrentContent()));
    setEditorState(editorState);
  }

  const _toggleBlock = (type) => setContent(RichUtils.toggleBlockType(editorState, type));
  const _toggleInlineStyle = (inlineStyle) => setContent(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    return (
      <div className="editor">
        <EditorControls onToggleInline={_toggleInlineStyle} onToggle={_toggleBlock} editorState={editorState}/>
        <Editor
          editorState={editorState}
          onChange={onChange}
          plugins={plugins}
          spellCheck
        />
      </div>
    );
}


export default MyEditor;
