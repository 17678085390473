import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import { store, useStore } from '../state/state';

store.state = process.env.REACT_APP_LOGGED_IN || false;

function Login(props) {

        props.toggleMenu(true);
    const [loggedIn, updateloggedIn]  = useStore();
    if(!process.env.REACT_APP_LOGGED_IN){
        fetch(`${process.env.REACT_APP_API}/api/auth/isloggedin`, {
            credentials: 'include'
        })
        .then((response) => response.json())
        .then((responseJson) => {
            updateloggedIn(responseJson.loggedIn);
        })
        .catch((error) => {
            console.error(error);
        });
    }
    return (
    <section className="firstSection">
      <Helmet>
        <title>404 - notFound</title>
        <meta name="description" content='Contact' />
        <meta property="og:title" content='Contact' />
      </Helmet>
      <div className="content">
        <form id="loginform" action={process.env.REACT_APP_API+'/api/auth/login?redirectTo='+window.location.origin} method="post">
          <h1>Login</h1>
          <span className="space">loggedin: {loggedIn.toString()}</span>
          <input type="text" name="username" placeholder="username"/>
          <input type="password" name="password" />
          <ReCAPTCHA sitekey="6LdXHrcUAAAAANi_rnacI8kPfrkK8kLRet_N_0En" />
          <input type="submit" />
        </form>
      </div>
    </section>
    )
}

export default Login;
