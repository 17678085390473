import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class IntroText extends Component {
    render(){
        return (
            <div className="content underlineSection">
                <div className="seventy">
                    <div className="space"></div>
                    <h2>What I can do for you</h2>
                    <p className="center">
                            I build web apps, services, digital tools ,... to achieve an easier, faster and and more connected world. <br/>
 Starting from an idea or problem, I break down the end goal into smaller, manageable parts.<br/>
                            This way, I can develop products in an agile and iterative way. <br/><br/>
                            I like working with motivated people and guiding them towards their technological goals.
                    </p>
                </div>
                <Link className="btn" to="/contact">Contact me</Link>
                <div className="space"></div>
            </div>
        );
    }
}

export default IntroText;
