
import React from 'react';
import { useState, useEffect } from 'react';

export default function(props) {
    const [images, setImages] = useState([]);
    const [uploadImg, setuploadImg] = useState(false);
    const [formError, setFormError] = useState("");
    let file = null;
    let fileAlt = '';
    function getData() {
        fetch(`${process.env.REACT_APP_API}/api/images`)
            .then((response) => response.json())
            .then((responseJson) => {
                setImages(responseJson);
            });
    }
    useEffect(() => {
        getData();
    }, []); // passing an empty array as second argument triggers the callback in useEffect only after the initial render thus replicating `componentDidMount` lifecycle behaviour

    const UpdatefileAlt = (e) => {
        fileAlt = e.target.value
    }
    const updateFile = (e) => {
        file = e.target.files[0]
    }
    const upload = (e) => {
        if(!file) {
            setFormError('Select a file');
            return;
        }
        if(!fileAlt) {
            setFormError('Select a file');
            return;
        }
        var data = new FormData()
        data.append('file', file)
        data.append('alt', fileAlt)
        data.append('name', file.name)
        fetch('/api/images', {
              method: 'POST',
              body: data
        }).then((data) => {
            getData();
        });
    }
    const toggleShowUpload  = (e) => {
        setuploadImg(!uploadImg);
    }
    function UploadImageComponent() {
        const ToggleButton = (props) => (<button onClick={toggleShowUpload}>{props.title}</button>);

        if(uploadImg) {
            return (
                <div className="uploadimgform">
                    <h4>Upload image</h4>
                    <span>{formError}</span>
                    <input type="file" name="file" onChange={updateFile}/>
                    <input type="text" name="alt" placeholder="description" onChange={UpdatefileAlt}/>
                    <button onClick={upload}>Upload</button>
                    <ToggleButton title="close"/>
                </div>
            );
        }
        return (
            <div className="uploadimgbtn">
                <ToggleButton title="Upload image"/>
            </div>
        );
    }
    const content = (
        <div className="imageHolder">
        <div className="header">
            <span onClick={props.toggleOpen}>Close</span>
        </div>
        <div className="imagesCollection">
        {
            images.map((image) => {
                let extraclass = 'image';
                if(props.project.images) {
                    const result = props.project.images.find((img) => {
                        return (image.link === img.link);
                    });
                    if(result){
                        extraclass += ' selected';
                    }
                    if(image.link === props.project.img){
                        extraclass += ' primary';
                    }
                    if(result && result.preview){
                        extraclass += ' previewImg';
                    }
                }
                return <div className={extraclass} key={image.id}>
                    <img
                        onClick={props.changeImg}
                        onContextMenu={props.changeImg}
                        imagelink={image.link}
                        imageid={image.id}
                        src={process.env.REACT_APP_API+image.link}
                        alt={image.alt}
                    />
                </div>
            })
        }
        </div>
        <UploadImageComponent/>
        </div>);
        let state = "close";
        if(props.open) {
            state = "open";
        }
        const classes = state +' imagePicker';
        return <div className={classes}>
        <button onClick={props.toggleOpen}>{props.button}</button>
        {content}
    </div>
}
