import React, { Component } from 'react';

class Loader extends Component {
    render() {
        return (
            <div className="loading">
                <img width="60px" height="60px" alt="loading" src="/loading.svg" />
            </div>
        );
    }
}

export default Loader;
