import React, { Component } from 'react';

class Notice extends Component {
    render() {
        if(this.props.show === true){
            return (
                <div className="noticeHolder">
                    <div className="notice">
                        <div className="icon">
                            <img src="check.svg" alt="github link"/>
                        </div>
                        <div className="message">{this.props.message}</div>
                    </div>
                </div>
            );
        }
        return (<div></div>);
    }
}

export default Notice;
