import ReactHtmlParser from 'react-html-parser';
import urlify from './urlify';

const BLOCK_TYPES = {
    'unstyled': {tag: 'p'},
    'header-one': {tag: 'h1'},
    'header-two': {tag: 'h2'},
    'header-three': {tag: 'h3'},
    'header-four': {tag: 'h4'},
    'header-five': {tag: 'h5'},
    'header-six': {tag: 'h6'},
    'unordered-list-item': {tag: 'ul', subtag: 'li'},
    'ordered-list-item': {tag: 'ol', subtag: 'li'},
};

const STYLE_TYPES = {
    'UNDERLINE': {tag: 'u'},
    'ITALIC': {tag: 'i'},
    'BOLD': {tag: 'b'},
    'CODE': {tag: 'm'},
};

function createStartTag(type, subtag) {
    const tag = subtag? BLOCK_TYPES[type].subtag : BLOCK_TYPES[type].tag;
    return `<${tag}>`;
}

function createEndTag(type, subtag) {
    const tag = subtag? BLOCK_TYPES[type].subtag : BLOCK_TYPES[type].tag;
    return `</${tag}>`;
}

function hasSubTag(line){
    return !!BLOCK_TYPES[line.type].subtag;
}

function addStylingToLine(line) {
    const arrayelements = line.inlineStyleRanges.reduce((accumulator, {style, length, offset}) => {
        if(accumulator[offset]) {
            accumulator[offset] += '<'+STYLE_TYPES[style].tag+'>';
        } else {
            accumulator[offset] = '<'+STYLE_TYPES[style].tag+'>';
        }
        if(accumulator[offset+length]) {
            accumulator[offset+length]  += '</'+STYLE_TYPES[style].tag+'>';
        } else {
            accumulator[offset+length]  = '</'+STYLE_TYPES[style].tag+'>';
        }
        if(!accumulator[offset]) {
            accumulator[offset]= null;
        };
        return accumulator;
    }, []);
    while( arrayelements.length > 0  ) {
        const pos = arrayelements.length -1;
        const style = arrayelements.pop();
        if(style) {
            line.text = `${line.text.substr(0, pos)}${style}${line.text.substr(pos)}`;
        }
    }
    return line.text;
}

function createElement(line, subtag) {
    return urlify(`${createStartTag(line.type, subtag)}${addStylingToLine(line)}${createEndTag(line.type, subtag)}`)
}

export default function format(content, loggedIn) {
    const { blocks } = content;
    let combined = '';
    if(blocks) {
        return blocks.map((line, index) => {
            const previousElement = blocks[index - 1];
            const nextElement = blocks[index + 1];
            if(hasSubTag(line)){
                if(!previousElement || previousElement.type !== line.type){
                    combined += createStartTag(line.type);
                }
                combined += createElement(line, true);
                if(!nextElement || (nextElement.type !== line.type)) {
                    combined += createEndTag(line.type);
                    const returnvalue = combined;
                    combined = '';
                    return (ReactHtmlParser(returnvalue));
                }
                return null;
            }
            return (ReactHtmlParser(createElement(line)));
        }).filter(x => x);
    }
    return JSON.stringify(content)
}
