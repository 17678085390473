import React from 'react';
import { useStore } from '../state/state';

import {
    Link,
} from 'react-router-dom'

function create(){
        return fetch(`${process.env.REACT_APP_API}/api/project`,
            {
                method: 'POST',
                cache: 'no-cache',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                redirect: 'follow',
                referrer: 'no-referrer',
                body: JSON.stringify({
                    title: 'New project',
                    short_description: 'short',
                    description: 'description',
                    date: Math.floor(new Date().getTime()/100),
                    slug: Math.floor(new Date().getTime()/100),
                    published: false,
                }),
            })
        .then((response) => {
                return response.json();
            })
        .then((myJson) => {
            window.location.href = `/projects/${myJson.data.slug}`;
        })
        .catch((error) => {
            console.error(error);
        });
    }
function Menu(props) {
    let classMenu = '';
    if(props.extraMenuClass){
        classMenu = 'blackmenu'
    }
    const [loggedIn]  = useStore();
    let logout = '';
    let newProject = '';
    if(loggedIn === true || process.env.REACT_APP_LOGGED_IN === 'true'){
        logout = (<li><a href={process.env.REACT_APP_API+'/api/auth/logout?redirectTo='+window.location.origin}>logout</a></li>)
        newProject = (<li><a href="/" onClick={create}>New Project</a></li>)
    }
    return (
        <div className={classMenu} >
            <div className="closeMenuLayer" onClick={props.handleClick}></div>
            <div className={[classMenu, "menuToggle"].join(' ')} onClick={props.handleClick}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
            <div className="menu">
                <ul>
                    {logout}
                    <li><Link onClick={props.handleClick} to="/">Home</Link></li>
                    <li><Link onClick={props.handleClick} to="/projects">Projects</Link></li>
                    {newProject}
                    <li><Link onClick={props.handleClick} to="/contact">Contact</Link></li>
                </ul>
            </div>
        </div>
    );
}
export default Menu;
