import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Loader from '../components/loading';
import VademoEditor from '../components/projectEditor';
import Footer from '../components/footer';
import contentFormatter from '../helpers/contentGenerator';
import { isJson } from '../helpers/typechecker';

import { useStore } from '../state/state';

import { Redirect } from 'react-router-dom'

function Content(props) {
    const [ loggedIn ] = useStore();
    let content = props.content;
    if(isJson(content)){
        content = JSON.parse(content);
    }
    if(loggedIn === true || process.env.REACT_APP_LOGGED_IN === 'true'){
        return(<VademoEditor
            onChangePublish={props.onChangePublish}
            onChangeTitle={props.onChangeTitle}
            onChangeSlug={props.onChangeSlug}
            onChangeShortDescription={props.onChangeShortDescription}
            content={content}
            project={props.page}
            change={props.change}
            update={props.update}
        />);
    }
    const formated = contentFormatter(content, loggedIn);
    return(<div>{formated}</div>);
}
class Page extends Component {
    constructor(props) {
        super(props);
        this.props.toggleMenu(true);
        this.state = {
            value: '',
            content: {},
            showpreview: false,
            height: 298,
            notFound: false,
            loading: true,
        };
        this.preview = React.createRef()
        this.newstate = {
        }
    }
    setContent(editorcontentRaw){
        this.setState({ editorcontentRaw });
    }
    componentWillUnmount(){
        document.removeEventListener("keydown", this.keyDown, true);
    }
    update(){
        return fetch(`${process.env.REACT_APP_API}/api/pages/${this.state.content.id}`,
            {
                method: "PATCH",
                cache: "no-cache",
                credentials: "include",
                headers: { "Content-Type": "application/json" },
                redirect: "follow",
                referrer: "no-referrer",
                body: JSON.stringify({
                    description:this.state.editorcontentRaw,
                    slug: this.state.content.slug,
                    date: new Date(this.state.content.date).getTime()/1000,
                    short_description: this.state.content.short_description,
                    title: this.state.content.title,
                    published: this.state.content.published,
                }),
            })
            .then((response) => {
                if(response.status === 200){
                    this.setState({updated: true})
                    setTimeout(() => this.setState({updated: false}), 1500);
                    this.componentDidMount();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }
    componentDidMount(){
        return fetch(`${process.env.REACT_APP_API}/api/pages/${this.props.match.params.slug}`)
            .then((response) => response.json())
            .then((responseJson) => {
                if(responseJson.errors && responseJson.errors[0].status === 404 ){
                    this.setState({notFound: true});
                }
                this.setState({
                    content: responseJson.data,
                    loading: false,
                    value: responseJson.data.description
                })
            })
            .catch((error) => {
                console.error(error);
            });
    }
    onChangeTitle(event) {
        this.setState(Object.assign( this.state.content, {title: event.target.value }));
    }
    onChangeSlug(event) {
        this.setState(Object.assign( this.state.content, {slug: event.target.value }));
    }
    onChangeShortDescription(event) {
        this.setState(Object.assign( this.state.content, {short_description: event.target.value }));
    }
    onChangeDate(event) {
        this.setState(Object.assign( this.state.content, {date: event.target.value }));
    }
    onChangePublish(event) {
        const target = event.target;
        this.setState(Object.assign( this.state.content, { published: target.checked }));
    }
    render() {
        if (this.state.notFound) return <Redirect to='/404' />;
        if (this.state.updated) return (<section className="firstSection"><Loader /></section>);
        if (this.state.loading) return (<section className="firstSection"><Loader /></section>);
        const { title, description,short_description, img } = this.state.content;
        return (
            <div>
            <section className="firstSection">
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={short_description} />
                    <meta name="twitter:image" content={process.env.REACT_APP_API+img} />
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={short_description} />
                    <meta property="og:description" content={short_description} />
                    <meta property="og:title" content={title} />
                    <meta property="og:image" name="image" content={process.env.REACT_APP_API+img} />
                </Helmet>
                <div className="content">
                    <div className="space"></div>
                    <h1 className="title">{title}</h1>
                    <div className="space small"></div>
                    <div className="text">
                        <Content
                            page={this.state.content}
                            onChangePublish={this.onChangePublish.bind(this)}
                            onChangeTitle={this.onChangeTitle.bind(this)}
                            onChangeSlug={this.onChangeSlug.bind(this)}
                            onChangeDate={this.onChangeDate.bind(this)}
                            onChangeShortDescription={this.onChangeShortDescription.bind(this)}
                            content={description}
                            change={(param) => this.setContent(param)}
                            update={() => this.update()}
                        />
                    </div>
                </div>
            </section>
            <Footer />
        </div>
        );
    }
}

export default Page;
