import React from 'react';
import { useState, useEffect } from 'react';

export default function(props) {
    const rotate = (direction) => {
        const img = images.map(img => process.env.REACT_APP_API + img.link);
        const currentImage = img.indexOf(previewUrl);
        let nextImage = currentImage + direction
        if(nextImage === img.length && direction > 0) nextImage = 0;
        if(nextImage < 0 && direction < 0) nextImage = img.length - 1;
        updatePreviewUrl(img[nextImage])
    }
    const updateUrl = (url) => {
        updatePreviewUrl(url)
        updatePreview(true);
    }
    const togglePreview = (state) => {
        if(state === false){
            return updatePreview(false);
        }
        updatePreview(!preview);
    }
    const keyDown = (e) => {
        var keyCode = e.keyCode;
        if(keyCode === 39) {
            rotate(1);
        }
        if(keyCode === 37) {
            rotate(-1)
        }
        if(keyCode === 27) {
            togglePreview(false)
        }
    }
    useEffect(() => {
        // This gets called after every render, by default
        // (the first one, and every one after that)
        document.addEventListener('keydown', keyDown);

        // If you want to implement componentWillUnmount,
        // return a function from here, and React will call
        // it prior to unmounting.
        return () => {
            return document.removeEventListener("keydown", keyDown);
        }
    });
    const img = "fixthis"
    const primaryName = "primaryName"
    const { height }= props;
    const images = props.images.filter((img) => {
        return !(img.primary || img.preview)
    });
    const [previewUrl, updatePreviewUrl] = useState('/');
    const [preview, updatePreview] = useState(false);
    if (images.filter(img => (!(img.primary || img.preview))).length === 0) {
        return <div></div>;
    }
    return(
        <div>
           <div className="projectImages">
            <h3 className="App-title">Images</h3>
            <div className="projectImagesHolder">
            {
                images.map((img, index) => {
                if(img.primary || img.preview) return null;
                return (
                  <div style={{height: height+"px"}} key={index} className="third">
                      <img
                          onClick={() => updateUrl(process.env.REACT_APP_API+img.link)}
                          src={process.env.REACT_APP_API+img.link}
                          alt={process.env.REACT_APP_API+img.name}
                      />
                  </div>
                );
              }).filter(x => x)
            }
           </div>
           </div>
           <div onClick={(e) => {togglePreview(process.env.REACT_APP_API+img);}} className={'preview ' + preview}>
               <div className="previmg" onClick={(e)=>{e.stopPropagation(); rotate(-1)}}>
                   <img src="/prev.svg" alt="previous"/>
               </div>
               <div className="nextimg" onClick={(e)=>{e.stopPropagation(); rotate(1)}}>
                   <img src="/next.svg" alt="previous"/>
               </div>
               <img onClick={e=>e.stopPropagation()} src={previewUrl} alt={primaryName} className="fullsize"/>
           </div>
       </div>
    );
}
