import React, { Component } from 'react';
import { Helmet } from "react-helmet";

class NotFound extends Component {
  constructor(props) {
    super(props);
    // set nemnu to black
    this.props.toggleMenu(true);
  }
  render() {
    return (
      <section className="firstSection">
        <Helmet>
          <title>404 - notFound</title>
          <meta name="description" content='Contact' />
           <meta property="og:title" content='Contact' />
        </Helmet>
        <div className="content">
          <h1 className="App-title">404 - Not found</h1>
        </div>
      </section>
    )
  }
}

export default NotFound;
