export function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function isObject(object) {
  return (typeof(object) == 'object');
}

