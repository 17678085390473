import Contact from './pages/Contact';
import Home from './pages/Home';
import NotFound from './pages/404';
import StyleGuide from './pages/styleguide';
import Project from './pages/Project';
import Page from './pages/Page';
import Projects from './pages/Projects';
import Login from './pages/Login';
import React from 'react';
import {
    Route,
    Redirect,
    Switch,
} from 'react-router-dom'

const Router = (props) => {
  const routerProps = props;
  return (
    <Switch>
      <Route exact path="/"><Home {...props}/></Route>
      <Route path="/404"><NotFound {...props}/></Route>
      <Route path="/contact"><Contact {...props}/></Route>
      <Route path="/styleguide"><StyleGuide {...props}/></Route>
      <Route path="/projects/:id/:x"><Redirect to='/404' /></Route>
      <Route path="/projects/:id" render={(props) => <Project {...props} toggleMenu={routerProps.toggleMenu}/> } />
      <Route path="/projects/"><Projects {...props}/></Route>
      <Route path="/login/"><Login {...props}/></Route>
      <Route path="/blog/:slug"><Page {...props}/></Route>
      <Route path="*"><Redirect to='/404' /></Route>
    </Switch>
  );
}

export default Router;
