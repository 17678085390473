import React, { Component } from 'react';

const mobileAndTabletcheck = function() {
    if(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)){
        return true;
    }
    return false;
};

const bgvideos = ["./canion.mp4", "./road.mp4", "./forest.mp4"];

class BgVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: "100%",
            height: 'auto',
            bgVideo: bgvideos[Math.floor(Math.random() * bgvideos.length)],
        }
        this.onChange = this.updateDimensions.bind(this);

    }
    updateDimensions(e){
        const video = this;
        const { innerWidth, innerHeight } = window;
        let ratio = 1920/1080;

        if(video.readyState > 1) {
            ratio = video.videoWidth/video.videoHeight;
        }
        if(innerWidth/ratio > innerHeight){
            this.setState({width:innerWidth});
            this.setState({height:innerWidth/ratio});
        } else {
            this.setState({height:innerHeight});
            this.setState({width:innerHeight*ratio});
        }
    }
    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.onChange, true);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.onChange, true);
    }
    render() {
        let video;
        if(!mobileAndTabletcheck()){
            video = <video autoPlay muted loop id="bgvideo" className="bgvideo" width={this.state.width} height={this.state.height}>
                <source src={this.state.bgVideo} type="video/mp4"/>
            </video>
        }
        return (
            <div className="bgvideoholder">
                {video}
                <div className="overlay"></div>
            </div>
        );
    }
}

export default BgVideo;
