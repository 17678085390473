import { Helmet } from "react-helmet";
import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import BgVideo from '../components/bgVideo';
import BgVideoSection from '../components/bgVideoSection';
import Footer from '../components/footer';
import IntroText from '../components/introText';
import ProjectPreview from '../components/projectPreview';

class Contact extends Component {
  constructor(props) {
    super(props);
    props.toggleMenu(false);
    this.state = {
      projects: []
    };
  }
  componentDidMount() {
    return fetch(`${process.env.REACT_APP_API}/api/project?limit=3`)
      .then((response) => response.json())
      .then((responseJson) => {
        const previewProjects = responseJson.data.map(
          project => ({
            key: project.slug,
            title: project.title,
            description: project.short_description,
            slug: project.slug,
            img: project.link
          }));
        this.setState({projects: previewProjects})
      })
      .catch((error) => {
        console.error(error);
      });
  }
  render() {
    const ogImg =`${process.env.REACT_APP_API}/FacebookShared.png`
    return (
      <div>
        <Helmet>
          <title>Vademo - Demystifying ideas</title>
          <meta name="description" content="I build web apps, services, digital tools ,... to achieve an easier, faster and and more connected world. Starting from an idea or problem, I break down the end goal into smaller, manageable parts. This way, I can develop products in an agile and iterative way. I like working with motivated people and guiding them towards their technological goals."/>
          <meta property="og:title" content="Vademo - Demystifying ideas"/>
          <meta property="og:image" name="image" content={ogImg} />
          <meta property="og:site_name" content="Vademo"/>
        </Helmet>
        <BgVideo/>
        <BgVideoSection toggleMenu={this.props.toggleMenu}/>
        <section className="fullheightSection">
          <div className="space"></div>
          <IntroText />
          <div className="content centertext">
            <div className="space bigger"></div>
            <h2>Previous projects</h2>
            <div className="twenty"></div>
            <p className="sixty centertext nomargintop nopaddingtop">Here I showcase some of my client stories. It’s not nearly all of the work I did, so if you need something specific or have a question, just let me know. I like a challenge!</p>
            <div className="space"></div>
          </div>
          {this.state.projects.map(function(project){
            return <ProjectPreview key={project.slug} project={project} />;
          })}
          <div className="content centertext">
            <div className="space big"></div>
            <Link className="btn" to="/projects">More projects </Link>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default Contact;
