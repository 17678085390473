import React, { Component } from 'react';
import TextEditor from './textEditor';
import ImageSelection from './imageSelection';

class VademoEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project: this.props.project,
            images: [],
            openImageSelection: false,
        };
        this.setContent = (editorState) => {
            this.props.change(editorState);
            this.setState({editorState});
        }
    }
    toggleOpen() {
        this.setState({ openImageSelection: !this.state.openImageSelection });
    };
    componentWillReceiveProps(nextProps) {
        this.setState({ project: nextProps.project });
    }
    render() {
        const ImgSelecter = () => {
            if(this.props.changeImg) {
                return (<ImageSelection
                    button="Select images"
                    project={this.props.project}
                    changeImg={this.props.changeImg}
                    open={this.state.openImageSelection}
                    toggleOpen={this.toggleOpen.bind(this)}
                />)
            }
            return null;
        };

        const DateField = () => {
            if(this.props.onChangeDate) {
                return (
                    <div>
                        <label htmlFor="date">Date</label>
                        <input type="date" name="date" onChange={this.props.onChangeDate} value={this.props.project.date}/>
                    </div>
                );
            }
            return null;
        };
        return (
            <div>
                <TextEditor onChange={this.setContent} content={this.props.content} change={this.props.change}/>
                <div className="metadata">
                    <h3>Metadata:</h3>
                    <label htmlFor="title">Published</label>
                    <input type="checkbox" name="published" onChange={this.props.onChangePublish}  defaultChecked={this.props.project.published}/>
                    <label htmlFor="title">Title</label>
                    <input type="text" name="title" onChange={this.props.onChangeTitle} value={this.props.project.title}/>
                    <label htmlFor="title">Slug</label>
                    <input type="text" name="slug" onChange={this.props.onChangeSlug} value={this.props.project.slug}/>
                    <DateField/>
                    <label htmlFor="title">description</label>
                    <textarea name="description" rows="10" onChange={this.props.onChangeShortDescription} value={this.state.project.short_description}>{this.state.project.short_description}</textarea>
                    </div>
                <ImgSelecter/>
                <button onClick={this.props.update}>Update</button>
            </div>
        );
    }
}

export default VademoEditor;
