import React, { Component } from 'react';

class ErrorList extends Component {
    render() {
        if(this.props.errors){
            let errors = this.props.errors;
            if(typeof(this.props.errors) === 'string'){
                errors = [this.props.errors];
            }
            return (
                <ul className="errorList">
                    {errors.map((e, i) => (<li key={i}>{e}</li>))}
                </ul>
            );
        }
        return (<div></div>);
    }
}

export default ErrorList;
