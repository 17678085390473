import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import Loader from '../components/loading';
import ProjectEditor from '../components/projectEditor';
import ProjectImg from './ProjectImg';
import Footer from '../components/footer';
import contentFormatter from '../helpers/contentGenerator';

import { isJson } from '../helpers/typechecker';
import { useStore } from '../state/state';

import { Redirect } from 'react-router-dom'

function Content(props) {
    const [ loggedIn ] = useStore();
    let content = props.content;
    if(isJson(content)){
        content = JSON.parse(content);
    }
    if(loggedIn === true || process.env.REACT_APP_LOGGED_IN === 'true'){
        return(<ProjectEditor
            onChangePublish={props.onChangePublish}
            onChangeTitle={props.onChangeTitle}
            onChangeSlug={props.onChangeSlug}
            onChangeDate={props.onChangeDate}
            onChangeShortDescription={props.onChangeShortDescription}
            content={content}
            project={props.project}
            change={props.change}
            changeImg={props.changeImg}
            update={props.update}
        />);
    }
    const formated = contentFormatter(content, loggedIn);
    return(<div>{formated}</div>);
}
class Project extends Component {
    constructor(props) {
        super(props);
        props.toggleMenu(true);
        this.state = {
            value: '',
            project: {
                images: []
            },
            showpreview: false,
            height: 298,
            notFound: false,
            loading: true,
        };
        this.preview = React.createRef()
        this.newstate = {
        }
    }
    setContent(editorcontentRaw){
        this.setState({ editorcontentRaw });
    }
    componentWillUnmount(){
        document.removeEventListener("keydown", this.keyDown, true);
    }
    update(){
        const images = this.state.project.images.map((img) => {
            return ({
                preview: img.preview,
                primary: img.primary,
                id: img.id,
            });
        });
        return fetch(`${process.env.REACT_APP_API}/api/project/${this.state.project.id}`,
            {
                method: "PATCH",
                cache: "no-cache",
                credentials: "include",
                headers: { "Content-Type": "application/json" },
                redirect: "follow",
                referrer: "no-referrer",
                body: JSON.stringify({
                    description:this.state.editorcontentRaw,
                    slug: this.state.project.slug,
                    date: new Date(this.state.project.date).getTime()/1000,
                    short_description: this.state.project.short_description,
                    title: this.state.project.title,
                    published: this.state.project.published,
                    images,
                }),
            })
            .then((response) => {
                if(response.status === 200){
                    this.setState({updated: true})
                    setTimeout(() => this.setState({updated: false}), 1500);
                    this.componentDidMount();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }
    componentDidMount(){
        this.setState(Object.assign( this.state.project, { short_description: 'short desc' }));
        return fetch(`${process.env.REACT_APP_API}/api/project/${this.props.match.params.id}`)
            .then((response) => response.json())
            .then((responseJson) => {
                if(responseJson.errors && responseJson.errors[0].status === 404 ){
                    this.setState({notFound: true});
                }
                const [img] = responseJson.data.images.filter((img) => img.primary);
                if(img){
                    responseJson.data.img = img.link;
                    responseJson.data.date = new Date(responseJson.data.date).toISOString().split('T')[0];
                    responseJson.data.primaryName = img.name;
                }
                responseJson.data.date = new Date(responseJson.data.date).toISOString().replace(/-|T.*/g,'-').slice(0, -1);
                this.setState({
                    project: responseJson.data,
                    loading: false,
                    value: responseJson.data.description
                })
            })
            .catch((error) => {
                console.error(error);
            });
    }
    changeImg(event) {
        event.preventDefault();
        let left = true;
        if (event.type === 'contextmenu') {
            left = false;
        }
        const imageid = event.target.getAttribute('imageid');
        const imagelink = event.target.getAttribute('imagelink');
        const found = this.state.project.images.find((img) => {
            return (img.link === imagelink);
        });
        const newproject = Object.assign({},  this.state.project);
        if(left){
            if(!found) {
                newproject.images.push({
                    link: imagelink,
                    id: imageid,
                })
            } else {
                newproject.images = newproject.images.filter((img) => {
                    if (img.link === imagelink) {
                        if(img.primary) {
                            newproject.img = '';
                        }
                        return false;
                    }
                    return true;
                });
            }
        } else {
            if(!found) {
                newproject.images.push({
                    link: imagelink,
                    id: imageid,
                    primary: false,
                })
                newproject.images = newproject.images.map((img) => {
                    img.primary = false;
                    if (img.link === imagelink){
                        img.primary = true;
                        newproject.img = imagelink;
                    }
                    return img;
                });
            } else if(found.primary !== true){
                newproject.images = newproject.images.map((img) => {
                    img.primary = false;
                    if (img.link === imagelink){
                        img.primary = true;
                        img.preview = false;
                        newproject.img = imagelink;
                    }
                    return img;
                });
            } else if(found.primary === true){
                newproject.images.map((img) => {
                    img.preview = false;
                    if (img.link === imagelink){
                        img.primary = false;
                        img.preview = true;
                        newproject.img = imagelink;
                    }
                    return img;
                });
                newproject.img = '';
            }
        }
        this.setState({project: newproject});
    }
    onChangeTitle(event) {
        this.setState(Object.assign( this.state.project, {title: event.target.value }));
    }
    onChangeSlug(event) {
        this.setState(Object.assign( this.state.project, {slug: event.target.value }));
    }
    onChangeShortDescription(event) {
        this.setState(Object.assign( this.state.project, {short_description: event.target.value }));
    }
    onChangeDate(event) {
        this.setState(Object.assign( this.state.project, {date: event.target.value }));
    }
    onChangePublish(event) {
        const target = event.target;
        this.setState(Object.assign( this.state.project, { published: target.checked }));
    }
    render() {
        if (this.state.notFound) return <Redirect to='/404' />;
        if (this.state.updated) return (<section className="firstSection"><Loader /></section>);
        if (this.state.loading) return (<section className="firstSection"><Loader /></section>);
        const { title, description,short_description, img, primaryName, date } = this.state.project;
        return (
        <div>
            <section className="firstSection">
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={short_description} />
                    <meta name="twitter:image" content={process.env.REACT_APP_API+img} />
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={short_description} />
                    <meta property="og:description" content={short_description} />
                    <meta property="og:title" content={title} />
                    <meta property="og:image" name="image" content={process.env.REACT_APP_API+img} />
                </Helmet>

                <div className="content">
                    <img src={process.env.REACT_APP_API+img} alt={primaryName}/>
                    <div className="space"></div>
                    <h1 className="title">{title}</h1>
                    <time dateTime={date} className="App-title">Date: {new Date(date).toLocaleDateString().replace(/\//g,'-')}</time>
                    <div className="space small"></div>
                    <div className="text">
                        <Content
                            project={this.state.project}
                            onChangePublish={this.onChangePublish.bind(this)}
                            onChangeTitle={this.onChangeTitle.bind(this)}
                            onChangeSlug={this.onChangeSlug.bind(this)}
                            onChangeDate={this.onChangeDate.bind(this)}
                            onChangeShortDescription={this.onChangeShortDescription.bind(this)}
                            content={description}
                            change={(param) => this.setContent(param)}
                            changeImg={this.changeImg.bind(this)}
                            update={() => this.update()}
                        />
                    </div>
                    <div className="space"></div>
                    <ProjectImg images={this.state.project.images}/>
                </div>
            </section>
            <Footer />
        </div>
        );
    }
}

export default Project;
