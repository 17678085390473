import React from 'react';

const BLOCK_TYPES = [
  {label: 'H1', style: 'header-one'},
  {label: 'H2', style: 'header-two'},
  {label: 'H3', style: 'header-three'},
  {label: 'H4', style: 'header-four'},
  {label: 'H5', style: 'header-five'},
  {label: 'H6', style: 'header-six'},
  {label: 'UL', style: 'unordered-list-item'},
  {label: 'OL', style: 'ordered-list-item'},
];
const INLINE_STYLES = [
  {label: 'Bold', style: 'BOLD'},
  {label: 'Italic', style: 'ITALIC'},
  {label: 'Underline', style: 'UNDERLINE'},
  {label: 'Monospace', style: 'CODE'},
];

const BlockStyleControls = (props) => {
  const {editorState} = props;
  const currentStyle = props.editorState.getCurrentInlineStyle();
  const selection = editorState.getSelection();
  const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();
  return (<div className="RichEditor-controls">
          {BLOCK_TYPES.map(({ label, style }) => <StyleButton key={label} active={style === blockType} label={label} onToggle={props.onToggle} style={style} />)}
          {INLINE_STYLES.map(({ label, style }) => <StyleButton key={label} active={currentStyle.has(style)} label={label} onToggle={props.onToggleInline} style={style} />)}
          </div>);
};

const StyleButton = (props) => {
    const onToggle = (e) => {
        e.preventDefault();
        props.onToggle(props.style);
    };
    let className = 'RichEditor-styleButton';
    if (props.active) {
        className += ' RichEditor-activeButton';
    }
    return (
      <span className={className} onMouseDown={onToggle}>
        {props.label}
      </span>
    );

}

export default BlockStyleControls;
