import React, { Component } from 'react';
import {
    Link,
} from 'react-router-dom'

function trimString(string, max) {
    if(string.length > max) {
        return string.substring(0, max - 3) + "...";
    }
    return string
}
class ProjectPreview extends Component {
    render() {
        const { title, description, img, slug } = this.props.project;
        const trimmedDescription = trimString(description, 200);
        let image = <img src={process.env.REACT_APP_API+img} alt={slug}/>
        if(!img){
            image = <img src="/placeholder.svg" alt={slug}/>
        }
        return (
        <div id={this.props.project.slug} className="content projectPreview">
            <div className="half">
                <Link to={'projects/'+slug}>
                   {image}
                </Link>
            </div>
            <div className="half">
                <h3>{title}</h3>
                <p>{trimmedDescription}</p>
                <Link to={'projects/'+slug}>
                    Read more<br/>
                </Link>
            </div>
        </div>
        );
    }
}

export default ProjectPreview;
