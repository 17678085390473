import React, { Component } from 'react';
class BgVideoSection extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    handleScroll = () => {
        if(this.myRef){
            const scrolltop = window.scrollY;
            const elementHeight = this.myRef.current.clientHeight
            if(scrolltop < elementHeight){
                return this.props.toggleMenu(false);
            } else {
                return this.props.toggleMenu(true);
            }
        }
    }
    render() {
        return (
          <section ref={this.myRef} className="bgvideoSection flexcenter centertext">
              <div className="logoHolder">
                  <img id="logo" src='/logo.svg' alt="logo Vademo" />
                  <h1>VADEMO</h1>
                  <h3>Demystifying ideas</h3>
              </div>
          </section>
        );
    }
}

export default BgVideoSection;
