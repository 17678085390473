import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Footer from '../components/footer';
import Loader from '../components/loading';
import {
    Link,
} from 'react-router-dom'

class Projects extends Component {
    constructor(props) {
        super(props);
        // set nemnu to black
        this.props.toggleMenu(true);
        this.state = {
            height: 360,
            project: {
                title: "",
                description: "",
                img: "",
                slug: ""
            },
            loading: true,
            projects: []
        };
        this.preview = React.createRef();
    }
    componentDidMount(){
        return fetch(`${process.env.REACT_APP_API}/api/project?imgType=preview`)
            .then((response) => response.json())
            .then((responseJson) => {
                const previewProjects = responseJson.data.map(
                    project => ({
                        title: project.title,
                        description: project.short_description,
                        slug: project.slug,
                        img: project.link
                    }));
                this.setState({
                  loading: false,
                  projects: previewProjects
                })
            })
            .catch((error) => {
                console.error(error);
            });
    }
    render() {
        //eslint-disable-next-line
        const { title, img } = this.state.project;
        if (this.state.loading) return (<section className="firstSection"><Loader /></section>);
        return (
        <div>
            <section className="firstSection projects">
                <Helmet>
                    <title>Projects</title>
                    <meta name="description" content="A selection of my work" />
                    <meta property="og:title" content="Projects" />
                </Helmet>
                <div className="content">
                    <h1 className="App-title">Previous projects</h1>
                </div>
                <div className="content">
                    {
                        this.state.projects.map(function(project){
                            let img = <img src={process.env.REACT_APP_API+project.img} alt={project.slug}/>
                            if(!project.img){
                                img = <img src="/placeholder_small.svg" alt={project.slug}/>
                            }
                            return <div key={project.slug} className="third project">
                                <Link to={'projects/'+project.slug}>
                                    {img}
                                </Link>
                            </div>
                        }, this)
                    }
                    <div ref={this.preview} className="third project"></div>
                </div>
            </section>
            <Footer />
        </div>
        );
    }
}

export default Projects;
