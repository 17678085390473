import React from 'react';
import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom'
import { createMemoryHistory  } from 'history';

import Router from './Router';
import Menu from './components/menu';
import Cookiebanner from './components/cookie';
import './sass/style.scss';

import { useStore } from './state/state';
import { useState } from 'react';

function App (props) {
  const history = createMemoryHistory();
  const cookies = new Cookies();
  const [consent, updateConsent] = useState(cookies.get('consent'));
  if(consent) {
    ReactGA.initialize('UA-140616358-1',{ cookieDomain: 'auto' });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  const setConsentNOk = () => {
    cookies.set('consent', false, {
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    });
    updateConsent(false);
  };
  const setConsentOk = () => {
    updateConsent(true);
    cookies.set('consent', true, {
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    });
    ReactGA.initialize('UA-140616358-1',{ cookieDomain: 'auto' });
    ReactGA.pageview(window.location.pathname + window.location.search);
  };
  const [menuColor, updatemenuColor] = useState('');
  // eslint-disable-next-line
  const [loggedIn, updateloggedIn] = useStore(false)
  if(!process.env.REACT_APP_LOGGED_IN){
    fetch(`${process.env.REACT_APP_API}/api/auth/isloggedin`, {
      credentials: 'include'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        updateloggedIn(responseJson.loggedIn);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  useEffect(() => {
    if(history) {
      const unsubscribe = history.listen((location) => {
        ReactGA.set({ page: location.pathname  })
        ReactGA.pageview(location.pathname)
      });
      return () => {
        unsubscribe();
      };
    }
  },[history]);
  const [menuOpen, updatemenuOpen] = useState('body closed');
  const handleClick = (e) => {
    if(menuOpen === 'body menuOpen menuOpend') {
      updatemenuOpen('body closed menuOpend');
    } else {
      updatemenuOpen('body menuOpen menuOpend');
    }
  }
  const getClass = () => {
    let isloggedinClass = '';
    if(loggedIn) isloggedinClass = 'loggedIn';
    return `${menuOpen} ${isloggedinClass}`;
  }
  return (
    <BrowserRouter>
      <div className={getClass()}>
        <Menu handleClick={handleClick} extraMenuClass={menuColor}/>
        <Router toggleMenu={updatemenuColor} />
        <Cookiebanner setConsentNOk={setConsentNOk}  setConsentOk={setConsentOk} consent={consent}/>
      </div>
    </BrowserRouter>
  );
}

export default App;
