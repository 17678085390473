import React from 'react';
function Cookiebanner(props) {
    if(props.consent !== undefined){
        return null;
    }
    return (
        <div className='consentbanner' >
            <h5>Cookie policy:</h5>
            <p>I'm using cookies to improve the experience of this website. I don't sell / share your personal data with third parties.</p>
            <div  className='btnholder' >
                <span onClick={props.setConsentNOk} className='btn small light smallmarginright'>Disable tracking</span>
                <span onClick={props.setConsentOk} className='btn small dark'>Ok</span>
            </div>
        </div>
    );
}
export default Cookiebanner;
