import React, { Component } from 'react';
class StyleGuide extends Component {
    render() {
        return (
            <div>
                <div class="bgvideoholder">
                    <video autoplay muted loop class="bgvideo">
                        <source src="resource/Grand Canyon - reversesable_3.mp4" type="video/mp4" />
                    </video>
                    <div class="overlay"></div>
                </div>
                <section class="bgvideoSection flexcenter centertext">
                    <div class="logoHolder">
                        <img id="logo" src="resource/logo.svg" alt="logo Vademo" />
                        <h1>VADEMO</h1>
                        <h2>Web
                            Developer</h2>
                    </div>
                </section>
                <section class="shadow">
                    <div class="content">
                        <h2 class="underline">Titles</h2>
                        <h1>This is a H1 title</h1>
                        <h2>This is a H2 title</h2>
                        <h3>This is a H3 title</h3>
                        <h4>This is a H4 title</h4>
                        <h5>This is a H5 title</h5>
                        <h6>This is a H6 title</h6>
                    </div>
                    <section>
                    </section>
                    <div class="content">
                        <h2 class="underline">Paragraph</h2>
                        <p>A Thursday evening project when I got a creative urge to draw. I started form a photo of I real wolf. I don't do a  ot of design work at least not this kind. I sometimes do UI elements but not really to design more for a functional purpose. But I'm happy with the result. Maybe I will let it print into a A3 for one of my frames. Update 11/1115 : I've got the idea to make a set of these to make the my living room a bit more uniform. Until now they were a bit random and had some outdated school projects inthem. I made the 3 other drawings with the same technique.</p>
                    </div>
                </section>
                <section class="shadow">
                    <div class="content">
                        <div class="half">
                            <h2 class="underline">Titles</h2>
                            <h1>This is a H1 title</h1>
                            <h2>This is a H2 title</h2>
                            <h3>This is a H3 title</h3>
                            <h4>This is a H4 title</h4>
                            <h5>This is a H5 title</h5>
                            <h6>This is a H6 title</h6>
                        </div>
                        <div class="half">
                            <p>A Thursday evening project when I got a creative urge to draw. I started form a photo of I real wolf. I don't do a  ot of design work at least not this kind. I sometimes do UI elements but not really to design more for a functional purpose. But I'm happy with the result. Maybe I will let it print into a A3 for one of my frames. Update 11/1115 : I've got the idea to make a set of these to make the my living room a bit more uniform. Until now they were a bit random and had some outdated school projects inthem. I made the 3 other drawings with the same technique.</p>
                        </div>
                    </div>
                </section>
                <section>
                   <div class="content">
                       <h2 class="underline">link</h2>
                   </div>
                   <div class="content">
                       <a href="/">a normal link </a><br/>
                       <a href="/" class="btn"> button link </a>
                   </div>
                </section>
                <section>
                    <div class="content">
                        <h2 class="underline">Colors</h2>
                    </div>
                    <div class="content">
                        <div class="fourth">
                            <div class="black colorholder">
                                <span class="whitetext">$black: #000000</span>
                            </div>
                        </div>
                        <div class="fourth">
                            <div class="dark-grey colorholder">
                                <span class="whitetext">$dark-grey #333333</span>
                            </div>
                        </div>
                        <div class="fourth">
                            <div class="white colorholder">
                                <span>$white #ffffff</span>
                            </div>
                        </div>
                        <div class="fourth">
                            <div class="black colorholder">
                                <span class="whitetext">This is a H1 title</span>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div class="content">
                        <h2 class="underline">Structure</h2>
                    </div>
                    <div class="content">
                        <div class="half">
                            <div class="borderd centertext structure">.half</div>
                        </div>
                        <div class="half">
                            <div class="borderd centertext structure">.half</div>
                        </div>
                    </div>
                    <div class="content">
                        <div class="half">
                            <div class="borderd centertext structure">.half</div>
                        </div>
                        <div class="fourth">
                            <div class="borderd centertext structure"> .fourth</div>
                        </div>
                        <div class="fourth">
                            <div class="borderd centertext structure"> .fourth</div>
                        </div>
                    </div>
                    <div class="content">
                        <div class="fourth">
                            <div class="borderd centertext structure"> .fourth</div>
                        </div>
                        <div class="fourth">
                            <div class="borderd centertext structure"> .fourth</div>
                        </div>
                        <div class="fourth">
                            <div class="borderd centertext structure"> .fourth</div>
                        </div>
                        <div class="fourth">
                            <div class="borderd centertext structure"> .fourth</div>
                        </div>
                    </div>
                </section>
                <section>
                    <h2 class="underline">Structure fullwidth</h2>
                    <div>
                        <div class="half">
                            <div class="borderd centertext structure">.half</div>
                        </div>
                        <div class="half">
                            <div class="borderd centertext structure">.half</div>
                        </div>
                    </div>
                    <div>
                        <div class="half">
                            <div class="borderd centertext structure">.half</div>
                        </div>
                        <div class="fourth">
                            <div class="borderd centertext structure"> .fourth</div>
                        </div>
                        <div class="fourth">
                            <div class="borderd centertext structure"> .fourth</div>
                        </div>
                    </div>
                    <div>
                        <div class="fourth">
                            <div class="borderd centertext structure"> .fourth</div>
                        </div>
                        <div class="fourth">
                            <div class="borderd centertext structure"> .fourth</div>
                        </div>
                        <div class="fourth">
                            <div class="borderd centertext structure"> .fourth</div>
                        </div>
                        <div class="fourth">
                            <div class="borderd centertext structure"> .fourth</div>
                        </div>
                    </div>
                </section>
                <section>
                    <div class="content projectPreview">
                        <div class="half">
                            <a href="/"><img src="./resource/bloem_1000.png" alt="alt"/></a>
                        </div>
                        <div class="half">
                            <h3> Wolf05</h3>
                            <p>A Thursday evening project when I got a creative urge to draw. I started form a photo of I real wolf. I don't
                            </p>
                            <a href="/">Read more</a><br/>
                        </div>
                    </div>
                    <div class="content projectPreview">
                        <div class="half">
                            <a href="/"><img src="./resource/bloem_1000.png" alt="alt"/></a>
                        </div>
                        <div class="half">
                            <h3> Wolf05</h3>
                            <p>A Thursday evening project when I got a creative urge to draw. I started form a photo of I real wolf. I don't
                                do a lot of design work at least not this kind. I
                            </p>
                            <a href="/" class="btn">Read more</a>
                        </div>
                    </div>
                    <div class="content projectPreview">
                        <div class="half">
                            <a href="/"><img src="./resource/wolf_1000.png" alt="alt"/></a>

                        </div>
                        <div class="half">
                            <h3> Wolf05</h3>
                            <p>A Thursday evening project when I got a creative urge to draw. I started form a photo of I real wolf. I don't
                                do a lot of design work at least not this kind. I
                            </p>
                            <a href="/">Read more</a><br/>
                        </div>
                    </div>
                </section>
                <section>
                    <div class="noticeHolder">
                        <div class="notice">
                            <div class="icon"> icon</div>
                            <div class="message"> message</div>
                        </div>
                    </div>
                </section>
            </div>)
    }
}

export default StyleGuide;
